// core overrides
$loader-bg: $body-bg;
$loader-logo: url('/img/brand/logo-symbol.svg');
$background-fullscreen: bg-custom;
$loader-circle-bg: #ddd;
$dropzone-bg: white;
$dropzone-border-color: $input-border-color;

// Navbar

$navbar-height: 70px;

$navbar-brand-width: 250px;
$navbar-brand-bg: lighten($gray-800, 1.6%);
$navbar-brand-logo: url('/img/brand/logo.svg');
$navbar-brand-logo-size: 156px auto;
$navbar-brand-logo-white: url('/img/brand/logo-white-horizontal.svg');
$navbar-brand-logo-symbol: url('/img/brand/logo-symbol.svg');
$navbar-border-color: $gray-800;

$navbar-bg: white;
$navbar-border: #eee;
$navbar-color: $gray-600;
$navbar-hover-color: darken($navbar-color, 20%);
$navbar-active-color: darken($navbar-color, 20%);
$navbar-disabled-color: lighter($navbar-color, 20%);

// Sidebar

$sidebar-width: 250px;
$sidebar-width-sm: 90px;
$sidebar-bg: $gray-800;

// Breadcrumb

$breadcrumb-margin: 0;
$breadcrumb-borders: 0;

$login-logo-width: 194px;

$credit-card-color: saturate(lighten(theme-color('primary'), 7%), 2%);
