.badge-pill {
  border-radius: $badge-pill-border-radius;
}

.badge {
  color: color('white');
}

.badge-lg {
  padding: 0.3rem 0.5rem;
}

.badge-default {
  background: lighten(theme-color('dark'), 30%);
}

.badge-inverse {
  background: $gray-100;
  color: desaturate(lighten($body-color, 10%), 5%);
}

.notifiable {
  position: relative;
  display: block;

  &fa-icon {
    .notify {
      top: 10px;
      right: -8px;
    }
  }
}

h6.notifiable {
  .notify {
    top: -3px;
    right: -22px;
  }
}

h4.notifiable {
  .notify {
    top: -18px;
    right: -7px;
  }
}

.avatar .notify {
  display: block;
  top: -24px;
  right: -2px;
}

.list-group-item-relation .notify {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.notify {
  position: relative;
}

.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid theme-color('danger');
  border-radius: 70px;
  animation: heartbit 1s ease-out;
  animation-iteration-count: infinite;
}

.notify .point {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  background-color: theme-color('danger');
  position: absolute;
  right: 6px;
  top: -10px;
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
