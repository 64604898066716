.img-round {
  border-radius: 50%;
}

.table-image {
  width: 36px;
}

img.grey {
  filter: grayscale(0.6);
  opacity: 0.9;
}
