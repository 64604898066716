.step {
  list-style: none;
  margin: 0.3rem 0 0;
  width: 100%;
  padding: 0;
}

.step .step-item {
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.step .step-item:not(:first-child)::before {
  background: theme-color('primary');
  content: '';
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item a {
  color: #acb3c2;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}

.step .step-item a::before {
  background: theme-color('primary');
  border-radius: 50%;
  content: '';
  display: block;
  height: 0.75rem;
  left: 50%;
  position: absolute;
  top: 0.2rem;
  transform: translateX(-50%);
  width: 0.75rem;
  z-index: 1;
}

.step .step-item.active a::before {
  background: #fff;
  border: 0.1rem solid theme-color('primary');
}

.step .step-item.failed a::before {
  background: #fff;
  border: 0.1rem solid theme-color('danger');
}

.step .step-item.active ~ .step-item::before,
.step .step-item.failed ~ .step-item::before {
  background: #e7e9ed;
}

.step .step-item.active ~ .step-item a::before,
.step .step-item.failed ~ .step-item a::before {
  background: #e7e9ed;
}

.step .step-item.active:last-child a::before {
  background: theme-color('primary');
  border: 0;
}
