.ng-select .ng-select-container {
  border-color: $border-color;
}

.ng-select .ng-dropdown-footer {
  padding: 0;
}

.powered-by-google-brand {
  float: right;
  height: 12px;
  margin: 4px;
}

.ng-select .ng-placeholder {
  color: $input-placeholder-color !important;
}
