.app-header .navbar {
  height: $navbar-height;
  background-color: $navbar-bg;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
  position: fixed;
  width: inherit;
  border-bottom: 1px solid #e6eaf0;

  .nav-link {
    height: $navbar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    color: $navbar-color;
    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    position: absolute;
  }

  .form-control {
    background: transparent;
    border: 0;
    color: $body-color;
    box-shadow: none !important;
    margin-left: -0.75rem;

    &::placeholder {
      color: $text-muted;
    }
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  &.navbar-dashboard {
    .navbar-toggler {
      border: 0;
    }

    .navbar-nav {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  @include media-breakpoint-down(md) {
    &.navbar-dashboard {
      background: $sidebar-bg;
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-dashboard {
    .btn-transparent {
      @include button-variant(transparent, transparent);
      color: $white;

      &:hover,
      &:active,
      &.active {
        color: $white !important;
        background-color: $gray-900 !important;
        border-color: $gray-900 !important;
      }
    }

    .dropdown.show {
      .btn-transparent.dropdown-toggle {
        color: $white !important;
        background-color: $gray-900 !important;
        border-color: $gray-900 !important;
      }
    }
  }
}

.nav-item.disabled,
.nav-item.disabled .nav-link {
  cursor: not-allowed !important;
}
