.ng-select {
  &.ng-single .ng-control {
    height: auto;
  }

  .ng-select-container {
    border-color: $input-border-color !important;
    height: 35px !important;
    color: $input-color !important;

    .ng-value-container {
      padding-left: $input-btn-padding-x !important;
    }
  }

  .ng-control {
    border-color: $input-border-color;
    color: $input-color !important;

    .ng-value-container {
      padding-left: 1rem !important;
      padding-right: 1rem !important;

      .ng-placeholder {
        color: $input-placeholder-color;
      }
    }
  }

  &.ng-select-lg {
    .ng-select-container {
      height: 42px !important;
      font-size: $font-size-lg !important;
      border-radius: var(--bs-border-radius-lg) !important;

      .ng-value-container {
        padding-left: $input-btn-padding-x-lg !important;
      }
    }
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: theme-color('primary');
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(theme-color('primary'), 0.1);
}

.form-validation {
  &.ng-submitted ng-select.ng-invalid,
  .ng-submitted ng-select.ng-invalid,
  ng-select.ng-invalid.ng-dirty {
    .ng-select-container {
      border-color: theme-color('danger') !important;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
    }
  }

  &.ng-submitted ng-select.ng-valid,
  .ng-submitted ng-select.ng-valid,
  ng-select.ng-valid.ng-dirty {
    .ng-select-container {
      border-color: theme-color('success') !important;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
    }
  }
}

.ng-select > .ng-select-container {
  background-color: $input-bg !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: $input-disabled-bg !important;
}

.ng-select-borderless {
  .ng-select-container {
    border: 0 !important;

    .ng-value-container .ng-value {
      border: 0 !important;
      background-color: transparent !important;
    }
  }
}

.ng-dropdown-panel {
  border-color: $input-border-color !important;
}

.ng-placeholder {
  color: $input-placeholder-color;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  width: 100%;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
  text-decoration: none !important;
  font-weight: 400 !important;
  color: theme-color('primary');
}

.ng-select.border-primary .ng-select-container {
  border-color: theme-color('primary') !important;
}

.ng-select.col {
  padding-left: 0;
  padding-right: 0;
}
