// Credit card

.cc {
  width: 300px;
  height: 178px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
  font-family: $font-family-monospace;
  margin: 0 auto 15px auto;
  box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);

  &.disabled {
    filter: grayscale(100%);
  }

  &-title {
    font-size: 11px;
    font-weight: 500;
    color: #c9d6de;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;

    & > span {
      margin-left: 87px;
    }
  }

  &-digit {
    font-size: 18px;
    font-weight: 400;
    color: #f4f5f9;
    letter-spacing: 0;
    text-align: left;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    text-shadow: 0px 1px 1px #333333;

    & > span {
      margin-left: 64px;
    }
  }

  &-wave {
    height: 300px;
    width: 300px;
    position: relative;
    background: desaturate(darken($credit-card-color, 10%), 6%);
    z-index: -1;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(
        to bottom,
        rgba(darken($credit-card-color, 0.5%), 0.9) 0%,
        rgba(darken($credit-card-color, 3%), 0.3) 100%
      );
      border-radius: 50% 50%;
    }

    &:after {
      height: 300px;
      width: 300px;
      left: 30%;
      top: 20%;
      opacity: 0.8;
    }

    &:before {
      height: 360px;
      width: 360px;
      left: -5%;
      top: -70%;
    }
  }

  &-content {
    width: 100%;
    padding: 20px;
    position: relative;
    float: left;
    z-index: 1;
  }

  &-logo {
    position: relative;
    margin-top: -20px;
    height: 70px;
  }

  &-mc-logo {
    position: absolute;
    bottom: 20px;
    right: 10px;
    height: 52px;
    width: 70px;
  }
}
