.btn-secondary {
  color: white;
}

.btn-shadow {
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-lg {
  font-size: $font-size-lg !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; // For some reason $btn-padding-y-lg has no effect
}

.btn-sm {
  font-size: $font-size-sm;
}

.btn-danger,
.btn-info,
.btn-primary,
.btn-success,
.btn-warning {
  color: color('white');
}

.btn-rounded {
  border-radius: 6px !important;
}

.btn-default {
  @include button-variant($gray-100, darken($border-color, 10%));
  color: $body-color;

  &:hover {
    color: $body-color;
  }
}

.btn-white {
  @include button-variant(white, white);
  color: theme-color('primary');

  &:hover {
    color: theme-color('primary');
  }
}

.btn-transparent {
  @include button-variant(transparent, transparent);
  color: $body-color;

  &:focus {
    color: $body-color !important;
  }

  &:hover,
  &:active,
  &.active {
    color: $body-color !important;
    background-color: $gray-200 !important;
    border-color: $gray-200 !important;
  }
}

.dropdown.show {
  .btn-transparent.dropdown-toggle {
    color: $body-color !important;
    background-color: $gray-200 !important;
    border-color: $gray-200 !important;
  }
}

.loading {
  color: transparent !important;
  min-height: 1.6rem;
  pointer-events: none;
  position: relative;
  transition: none;

  &::after {
    animation: loading 500ms infinite linear;
    border: 0.2rem solid white;
    border-radius: 0.8rem;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 1.2rem;
    left: 50%;
    margin-left: -0.6rem;
    margin-top: -0.6rem;
    position: absolute;
    top: 50%;
    width: 1.2rem;
  }

  &-inverse {
    &::after {
      border-color: $gray-200;
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-icon {
  position: relative;
  overflow: hidden;
  padding-left: 3.125rem !important;
  padding-right: 0.875rem !important;
  text-overflow: ellipsis;

  &-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2.25rem;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
    background: rgba(0, 0, 0, 0.05);
  }
}

.btn-card-header {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  margin-top: -18px !important;
}

.btn-outline-white {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.85);

  &:focus,
  &:hover {
    border-color: white;
    color: white;
  }
}

.btn-w-lg {
  min-width: 300px !important;
}

.btn-w-md {
  min-width: 180px !important;
}

.btn-w-sm {
  min-width: 120px !important;
}

.btn-icon-symbol-sm {
  line-height: 1.5rem;
}

.upload-button {
  input {
    display: none;
  }
}

// Only used for modal close, a general purpose use needs sub classes
.btn-round {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  padding: 0 !important;
  background: rgba(255, 255, 255, 0.25) !important;
  color: white !important;
  opacity: 1 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem !important;
  font-weight: 200 !important;
  &.btn-primary {
    background: theme-color('primary');
    &:hover {
      background: darken(theme-color('primary'), 10%);
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.35) !important;
  }

  &.btn-round-inverse {
    background: rgba(0, 0, 0, 0.075) !important;
    color: $headings-color !important;

    &:hover {
      background: rgba(0, 0, 0, 0.15) !important;
    }
  }
}

/*------------------------------------
  Air Button Styles
------------------------------------*/
/* primary air */
.btn-primary-air {
  color: theme-color('primary') !important;
  background: rgba(theme-color('primary'), 0.1) !important;
  border-color: transparent !important;
}

.btn-primary-air:hover,
.btn-primary-air:focus,
.btn-primary-air:active,
.btn-primary-air.active {
  color: #fff !important;
  background: theme-color('primary') !important;
  box-shadow: 0 4px 17px rgba(theme-color('primary'), 0.35) !important;
}

/* secondary air */
.btn-secondary-air {
  color: theme-color('secondary') !important;
  background: rgba(theme-color('secondary'), 0.1) !important;
  border-color: transparent !important;
}

.btn-secondary-air:hover,
.btn-secondary-air:focus,
.btn-secondary-air:active,
.btn-secondary-air.active {
  color: #fff !important;
  background: theme-color('secondary') !important;
  box-shadow: 0 4px 17px rgba(theme-color('secondary'), 0.35) !important;
}

/* success air */
.btn-success-air {
  color: theme-color('success') !important;
  background: rgba(theme-color('success'), 0.1) !important;
  border-color: transparent !important;
}

.btn-success-air:hover,
.btn-success-air:focus,
.btn-success-air:active,
.btn-success-air.active {
  color: #fff !important;
  background: theme-color('success') !important;
  box-shadow: 0 4px 17px rgba(theme-color('success'), 0.35) !important;
}

/* info air */
.btn-info-air {
  color: theme-color('info') !important;
  background: rgba(theme-color('info'), 0.1) !important;
  border-color: transparent !important;
}

.btn-info-air:hover,
.btn-info-air:focus,
.btn-info-air:active,
.btn-info-air.active {
  color: #fff !important;
  background: theme-color('info') !important;
  box-shadow: 0 4px 17px rgba(theme-color('info'), 0.35) !important;
}

/* warning air */
.btn-warning-air {
  color: theme-color('warning') !important;
  background: rgba(theme-color('warning'), 0.1) !important;
  border-color: transparent !important;
}

.btn-warning-air:hover,
.btn-warning-air:focus,
.btn-warning-air:active,
.btn-warning-air.active {
  color: #fff !important;
  background: theme-color('warning') !important;
  box-shadow: 0 4px 17px rgba(theme-color('warning'), 0.35) !important;
}

/* danger air */
.btn-danger-air {
  color: theme-color('danger') !important;
  background: rgba(theme-color('danger'), 0.1) !important;
  border-color: transparent !important;
}

.btn-danger-air:hover,
.btn-danger-air:focus,
.btn-danger-air:active,
.btn-danger-air.active {
  color: #fff !important;
  background: theme-color('danger') !important;
  box-shadow: 0 4px 17px rgba(theme-color('danger'), 0.35) !important;
}

.btn-options {
  color: $gray-600;
  padding-top: 0;
  padding-bottom: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.25rem;

  &.dropdown-toggle::after {
    content: none;
  }
}

.dropdown-toggle-split:not(.btn-primary-air) {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-default:disabled {
  color: lighten(#495057, 10%);
}
