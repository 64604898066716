$bg_color_default: #333;
$bg_color_success: theme-color('success');
$bg_color_info: theme-color('info');
$bg_color_warning: theme-color('warning');
$bg_color_error: theme-color('danger');

toastr {
  display: block;
  position: sticky;
  z-index: 99999;
  top: 0;
  height: 0;
  * {
    box-sizing: border-box;
  }
}

.app-header + .main toastr {
  top: $navbar-height;
}

.toastr-anchor {
  position: absolute;
  right: 0;
  padding: 12px;
  overflow-x: hidden;
}

.toastr-item-message {
  margin: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toastr-item {
  position: relative;
  overflow: hidden;
  margin: 0 0 0.75rem;
  padding: 15px;
  width: 300px;
  border-radius: 4px;
  color: #ffffff;
  background-color: $bg_color_default;
  box-shadow: rgba(23, 43, 99, 0.175) 0 8px 20px;

  &:hover {
    cursor: pointer;
  }

  &.toastr-item-success {
    background-color: $bg_color_success;

    &:hover {
      background-color: darken($bg_color_success, 3%);
    }
  }

  &.toastr-item-error {
    background-color: $bg_color_error;

    &:hover {
      background-color: darken($bg_color_error, 3%);
    }
  }

  &.toastr-item-info {
    background-color: $bg_color_info;

    &:hover {
      background-color: darken($bg_color_info, 3%);
    }
  }

  &.toastr-item-warning {
    background-color: $bg_color_warning;

    &:hover {
      background-color: darken($bg_color_warning, 3%);
    }
  }
}
