//
//
// dropdown.scss
//
//

// Bootstrap overrides
//
// General overrides for default dropdown styles

.dropdown-item {
  position: relative;
  padding: 10px 20px;

  &:last-child {
    border-bottom: 0;
  }

  i {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: $dropdown-border-color;
    text-align: center;
  }

  &.active {
    border-left: 3px solid theme-color('primary');
    padding-left: 17px;
  }
}

.dropdown-menu {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.35s ease 5s;
  animation-duration: 200ms;
  animation-fill-mode: both;

  &.show {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    animation-name: showDropdown;
  }
}

.dropdown-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dropdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $headings-color;
  }

  .text-muted {
    color: $gray-600 !important;
  }

  .title-decorative {
    color: $gray-500;
    margin: 0;
    font-size: 0.75rem;
    letter-spacing: 0.75px;
  }
}

// Dropdown additions
//
//

.dropdown-menu-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  .dropdown-item,
  .dropdown-header {
    padding: 0.25rem 1rem;
  }

  .dropdown-divider {
    margin: 0.5rem 0;
  }
}

.dropdown-toggle-no-arrow {
  &:after {
    display: none !important;
  }
}

.app-header {
  .navbar-nav {
    .dropdown-menu {
      &.dropdown-menu-w-lg {
        min-width: 400px;
        max-width: 400px;

        .dropdown-item {
          white-space: initial;
        }
      }
    }

    // Menu positioning
    //
    // Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
    // menu with the parent.
    .dropdown-menu-right {
      right: 0;
      left: auto; // Reset the default from `.dropdown-menu`
    }

    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}

.dropdown-menu-lg {
  min-width: 250px;
}

.dropdown-header {
  position: relative;
  font-size: $font-size-base;

  .btn {
    position: absolute;
    right: 0;
    top: 2px;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(theme-color('primary'), 0.1);
  color: theme-color('primary') !important;
}

// Dropdown animation
@keyframes showDropdown {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
