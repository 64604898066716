// scss-lint:disable QualifyingElement
hr.transparent {
  border-top: 1px solid transparent;
}

a.disabled {
  pointer-events: none;
}

.header {
  &-body {
    margin-top: -2rem;
  }
}
