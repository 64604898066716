.popover {
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
}

.popover-lg {
  max-width: 500px;
}

@include media-breakpoint-down(sm) {
  .popover {
    left: 0 !important;
    max-width: 100%;
    margin: 0 10%;
  }
}

.popover-flex {
  max-width: none !important;
}

.popover-dark {
  background-color: $dark;
  border: 1px solid $dark;

  .popover-body {
    background-color: $dark;
  }

  .arrow {
    &::before {
      border-bottom-color: $dark;
    }
    &::after {
      border-bottom-color: $dark;
    }
  }
}
