@import 'header-notifications/header-notifications.component';

@media (max-width: 767px) {
  .nav-balance {
    .dropdown-menu {
      left: 50px !important;
    }
  }
}

.feather {
  color: theme-color('primary');
  height: 24px;
  width: 24px;
  margin-left: -0.15rem;
  margin-right: 0.375rem;
}
