.kyc-wizard {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.kyc-header {
  background-color: #ebeff5 !important;
  padding: 0.125rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #dae1ec !important;
}

.kyc-logo {
  img {
    display: block;
    width: $login-logo-width;
    margin: 1.5rem auto;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.025));
  }
}

.card .list-group {
  --bs-list-group-bg: #fff;

  .list-group-item {
    & > fa-icon {
      color: $gray-400;
    }

    &.active {
      background-color: #fff;
      border-color: $border-color;
      color: var(--bs-list-group-color);
      border-left: 0.125rem solid theme-color('primary');
      padding: 1.125rem 1.5rem 1.125rem 1.375rem;

      & > fa-icon {
        color: theme-color('primary');
      }
    }

    &.success {
      & > fa-icon {
        color: theme-color('success');
      }
    }

    &:disabled {
      background-color: theme-color('light');
    }
  }
}

.card-btn {
  border: 0;
  font-weight: 500;
  color: white;

  &-primary {
    background: theme-color('primary');
    background: linear-gradient(
      32deg,
      lighten(theme-color('primary'), 2%) 35%,
      lighten(theme-color('primary'), 13%) 100%
    );

    &:hover {
      background: linear-gradient(
        32deg,
        lighten(theme-color('primary'), 2%) 15%,
        lighten(theme-color('primary'), 10%) 100%
      );
    }
  }

  &-primary-air {
    color: theme-color('primary') !important;
    background: rgba(theme-color('primary'), 0.1) !important;
    border-color: transparent !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff !important;
      background: theme-color('primary') !important;
      box-shadow: 0 4px 17px rgba(theme-color('primary'), 0.35) !important;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.cursor-default:hover {
  cursor: default;
}

.fill-primary {
  fill: theme-color('primary');
}

.wizard-steps {
  list-style: none;
  margin: 0;
  width: 100%;
  display: flex;
  padding: 0;
}

.wizard-steps .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.wizard-steps .step-item:not(:first-child)::before {
  background: theme-color('primary');
  content: '';
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.wizard-steps .step-item a {
  color: $gray-500;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}

.wizard-steps .step-item a::before {
  background: theme-color('primary');
  border: 0.1rem solid #fff;
  border-radius: 50%;
  content: '';
  display: block;
  height: 0.9rem;
  left: 50%;
  position: absolute;
  top: 0.2rem;
  transform: translateX(-50%);
  width: 0.9rem;
  z-index: 1;
}

.wizard-steps .step-item.active a::before {
  background: #fff;
  border: 0.1rem solid theme-color('primary');
}

.wizard-steps .step-item.active ~ .step-item::before {
  background: #e7e9ed;
}

.wizard-steps .step-item.active ~ .step-item a::before {
  background: #e7e9ed;
}
