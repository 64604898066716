.card-terms-container {
  overflow-y: auto;
  height: 50vh;
  min-height: 320px;

  .container {
    padding: 0 !important;
  }
  ol {
    padding-left: 1rem !important;

    ol {
      margin-bottom: 1.5rem !important;
    }
  }
}

.view-terms-modal {
  z-index: 100000 !important;
}

.brand-box {
  border: 1px solid #d3d8e4;
  border-radius: 12px;
}

.card-shadow {
  box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
}
