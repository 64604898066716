html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

b,
strong,
.font-weight-bold {
  font-weight: 500;
}

*:focus {
  outline: none;
}

.font-xs {
  font-size: 0.5rem !important;
}

.font-sm {
  font-size: 0.8rem !important;
}

.font-md {
  font-size: 0.875rem !important;
}

.font-lg {
  font-size: 1rem !important;
}

.font-xl {
  font-size: 1.25rem !important;
}

.font-2xl {
  font-size: 1.5rem !important;
}

.font-3xl {
  font-size: 1.75rem !important;
}

.font-4xl {
  font-size: 2rem !important;
}

.font-5xl {
  font-size: 2.5rem !important;
}

.font-no-decoration {
  text-decoration: none !important;
}

.title-decorative {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $gray-500;
  margin-bottom: calc($spacer/2);
  display: block;
  font-weight: 500;
  &:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(sm) {
  .title-decorative {
    margin-bottom: $spacer;
  }
}

h1,
.h1 {
  font-weight: 300;
  line-height: 3rem;
}

h2,
.h2 {
  line-height: 2.5rem;
}

h3,
.h3 {
  line-height: 2.25rem;
}

h4,
.h4 {
  line-height: 1.875rem;
  margin-bottom: $spacer * 2;
}

h5,
.h5 {
  line-height: 1.875rem;
  margin-bottom: calc($spacer/2);
}

h6,
.h6 {
  line-height: 1.5rem;
  margin-bottom: calc($spacer/2);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

.lead {
  margin-bottom: $spacer;
  line-height: 1.875rem;
  display: block;
  font-weight: $font-weight-normal;
}

@each $current-color in $colors {
  $key: nth($current-color, 1);
  $value: nth($current-color, 2);
  .text-#{$key} {
    color: $value !important;
  }
}

.headline {
  background: transparent;
  position: relative;
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $input-border-color;
  margin-bottom: $spacer;
  color: $body-color;
  display: block;

  small {
    font-size: 0.875rem;
    line-height: 2.1;
  }

  &::before {
    content: ' ';
    position: absolute;
    bottom: -1px;
    width: 60px;
    height: 1px;
    background-color: theme-color('primary');
  }
}

.text-label {
  color: #7f8fa4 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

address {
  margin-bottom: 0;

  &.address-input {
    background-color: $input-disabled-bg;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    padding: $input-padding-y $input-padding-x;
  }

  &.address-bg-light {
    background-color: #fafafa !important;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    padding: $input-padding-y $input-padding-x;
  }
}

h6.hr {
  &:before {
    content: ' ';
    display: block;
    position: relative;
    height: 1px;
    background-color: red;
    top: 0.75rem;
  }
}

.text-primary-hover:hover {
  color: theme-color('primary') !important;
}

.text-primary-light {
  color: rgba(theme-color('primary'), 0.75) !important;
}

hr {
  border-color: $border-color;
  opacity: 1;
}
