.login-page {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.login-logo {
  display: block;
  width: $login-logo-width;
  margin: 1.5rem auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.025));
}

.alert-login {
  width: 100%;
  max-width: 430px;
  margin: 0 auto 1rem;
  padding-right: 45px;
}

.login-box {
  width: 100%;
  max-width: 440px;
  margin: 0 auto 0.75rem;

  .ng-submitted .password-wrong.ng-invalid {
    border-color: var(--bs-form-invalid-border-color);

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
    }
  }
}

.login-or {
  position: relative;
  color: #999;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-top: 10px;
  padding-bottom: 10px;

  hr {
    background-color: #cdcdcd;
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -25px;
    background-color: #fff;
    width: 50px;
    text-align: center;
    text-transform: uppercase;
  }
}
