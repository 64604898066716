.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-dark {
  color: #fff;
}

.bg-grey {
  background: $gray-200;
}

.bg-body {
  background-color: $body-bg !important;
}

// used in frontend fullscreen service
.fullscreen-bg-body {
  @extend .#{$background-fullscreen};
  color: $body-color;
}
