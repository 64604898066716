@mixin sidebar-wrapper-toggled {
  .sidebar-wrapper,
  .sidebar {
    width: $sidebar-width-sm;
  }

  .sidebar-wrapper + .app-body {
    width: calc(100% - #{$sidebar-width-sm});
  }
}

// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

.app {
  display: flex;
  min-height: 100vh;
}

.sidebar-wrapper + .app-body {
  width: calc(100% - #{$sidebar-width});
}

.sidebar {
  z-index: 1002;
}

.app-header {
  z-index: 1001;
  height: $navbar-height;
  width: inherit;
}

.app-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  min-width: 0;
  .main {
    flex: 1;
    min-width: 0;
  }
}

.sidebar-toggled {
  @include sidebar-wrapper-toggled;
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
  @include sidebar-wrapper-toggled;
}

@include media-breakpoint-down(md) {
  .app-header {
    width: 100% !important;
  }
  .sidebar-wrapper,
  .sidebar {
    position: fixed;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    margin-left: -100%;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    .sidebar-nav,
    .sidebar-nav .nav {
      width: 100% !important;
    }
  }

  .sidebar-show {
    overflow: hidden;

    .sidebar-wrapper,
    .sidebar {
      transform: translateX(100%);
    }
  }

  .navbar .dropdown-menu {
    position: fixed !important;
    top: $navbar-height !important;
    left: 0 !important;
    width: 100vw !important;
    min-width: 0 !important;
    max-width: none !important;
    transform: none !important;
    margin: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
