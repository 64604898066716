@import 'utilities/background';
@import 'utilities/borders';
@import 'utilities/display';

.bg-transparent-alt {
  background-color: #ebeff5 !important;
  border-color: #dae1ec !important;
}

button.list-group-item.bg-transparent-alt:hover {
  background-color: darken(#ebeff5, 1%) !important;
  border-color: darken(#dae1ec, 1%) !important;
}

.bg-transparent-alt-odd {
  background-color: darken(#ebeff5, 2.5%) !important;
  border-color: darken(#dae1ec, 2.5%) !important;
}

.bg-transparent-alt-highlight {
  background-color: lighten(#ebeff5, 4%) !important;
}

.bg-white {
  background-color: #fff !important;
  /* Needs when used in tables with hover state */
  --bs-table-bg-state: #fff !important;
}

.bg-light {
  background-color: #fafafa !important;
}

.bg-medium-light {
  background-color: lighten($gray-100, 0.5%) !important;
}

.bg-body-light {
  background-color: lighten($body-bg, 0.7%) !important;
}

.bg-primary-dark {
  background-color: darken(theme-color('primary'), 1%) !important;
}

.bg-accent-even:nth-child(even) {
  background-color: $table-accent-bg !important;
}

.bg-gray-dark {
  background-color: color('gray-dark') !important;
}

.bg-table-striped {
  background-color: $table-striped-bg !important;
}

.blurred {
  filter: blur(4px);
}

.overflow-hidden {
  overflow: hidden;
}

// max-width
.max-width-xs {
  max-width: 170px;
}

.max-width-sm {
  max-width: 430px;
}

.max-width-md {
  max-width: 540px;
}

.max-width-lg {
  max-width: 640px;
}

.max-width-xl {
  max-width: 880px;
}

// filter
.gray-scale {
  filter: grayscale(100%);
}

// image
.img-xxxs {
  width: 25px;
  height: auto;
}

.img-xxs {
  width: 36px;
  height: auto;
}

.img-xs {
  width: 48px;
  height: auto;
}

.img-sm {
  width: 80px;
  height: auto;
}

.img-md {
  width: 120px;
  height: auto;
}

.img-lg {
  width: 100%;
  max-width: 180px;
  height: auto;
}

.active .text-activatable,
.highlighted .text-activatable {
  color: white !important;
}

.d-inline-grid {
  display: inline-grid;
}

.centered {
  margin: 0 auto;
}

.text-line-through {
  text-decoration: line-through;
}

.lh-1 {
  line-height: 1;
}

.br-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.br-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.space-0 {
  padding: 0;
}

.space-xxs {
  padding-top: calc($spacer/2);
  padding-bottom: calc($spacer/2);
}

.space-xs {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.space-sm {
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
}

@include media-breakpoint-up(lg) {
  .space-lg {
    padding-top: $spacer * 5;
    padding-bottom: $spacer * 5;
  }
  .space-xlg {
    padding-top: $spacer * 7;
    padding-bottom: $spacer * 7;
  }
}

@include media-breakpoint-down(lg) {
  .space-lg {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
  }
  .space-xlg {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
  }
}

section + section.flush-with-above {
  border-top: none !important;
  padding-top: 0;
}

.scrollable {
  max-height: 330px;
  overflow: auto;

  &-lg {
    max-height: 470px;
  }

  &-y-only {
    overflow-x: hidden;
  }
}

.tooltip-flex + .tooltip {
  text-overflow: ellipsis;
  white-space: nowrap;

  .tooltip-inner {
    max-width: initial !important;
  }
}

/*------------------------------------
  Width
------------------------------------*/
.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.w-15 {
  width: 15% !important;
}

.h-0 {
  height: 0;
}

/*------------------------------------
  Label
------------------------------------*/
.label {
  display: inline-block;
  font-weight: 500;
  line-height: 1.3;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 6.1875rem;

  &-sm {
    font-size: 0.6875rem;
    line-height: 1.4;
    padding: 0.25rem 0.75rem;
  }

  &-xs {
    font-size: 0.625rem;
    line-height: 1.3;
    padding: 0.15rem 0.45rem;
    font-weight: 400;
  }
}

.label-primary {
  color: theme-color('primary');
  background-color: rgba(theme-color('primary'), 0.1);
}

.label-secondary {
  color: theme-color('secondary');
  background-color: rgba(theme-color('secondary'), 0.1);
}

.label-success {
  color: theme-color('success');
  background-color: rgba(theme-color('success'), 0.1);
}

.label-info {
  color: theme-color('info');
  background-color: rgba(theme-color('info'), 0.1);
}

.label-warning {
  color: theme-color('warning');
  background-color: rgba(theme-color('warning'), 0.1);
}

.label-danger {
  color: theme-color('danger');
  background-color: rgba(theme-color('danger'), 0.1);
}

.label-medium {
  color: theme-color('medium');
  background-color: rgba(theme-color('medium'), 0.1);
}

.label-dark {
  color: theme-color('dark');
  background-color: rgba(theme-color('dark'), 0.1);
}

.custom-switch-light {
  .form-check-input:checked {
    ~ .form-check-label {
      &::before {
        background-color: #fff;
        border-color: #fff;
      }
      &::after {
        background-color: #3983ec;
      }
    }
  }
}

.min-width-sm {
  min-width: 150px;
}

.min-width-md {
  min-width: 250px;
}

.min-width-lg {
  min-width: 350px;
}

.clickable {
  cursor: pointer !important;

  &-info:hover {
    color: theme-color('info');
  }
}

.cursor-default {
  cursor: default !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.pt-page {
  padding-top: 15px;
}

.custom-list {
  list-style: none;
  padding-inline-start: 50px;

  div {
    display: flex;
    flex-wrap: wrap;

    p {
      &:first-child {
        flex-basis: 50px;
      }
      &:nth-child(2) {
        flex-basis: 85%;
      }
    }

    span {
      padding-inline-start: 50px;
    }
  }

  &.no-padding {
    padding-inline-start: 0px !important;
  }
}

.overlay {
  position: relative;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.025);
    top: 0;
    left: 0;
  }
}

.container-sm {
  max-width: 1040px !important;
}

.m--1px {
  margin: -1px;
}
