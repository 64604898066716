@mixin toggled-sidebar {
  .navbar .navbar-brand {
    width: $sidebar-width-sm;
    font-size: 0;
    background-size: 56px;
    background-image: $navbar-brand-logo-symbol;

    svg {
      margin: 0 !important;
    }
  }

  .nav-heading {
    font-size: 0;
  }

  .nav-link {
    border-right: 0.25rem solid transparent;
    text-align: center;

    span {
      display: none;
    }

    .nav-icon {
      display: block;
      min-width: 0;
      margin-left: 0;
    }

    .nav-caret {
      display: none;
    }
  }

  .btn-icon {
    min-height: 40px;
    padding-right: 0 !important;
    padding-left: 0 !important;

    .btn-icon-symbol {
      width: 100%;
    }

    span {
      display: none;
    }
  }

  .tooltip {
    display: block;
  }

  .sidebar-profile {
    font-size: 0;
  }

  .list-group {
    display: none;
  }
}

.sidebar-wrapper {
  width: $sidebar-width;
  flex-shrink: 0;
}

.sidebar {
  position: fixed;
  background: $sidebar-bg;
  height: 100vh;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;

  .navbar {
    height: $navbar-height;
    padding: 0;
    position: relative;

    .navbar-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $navbar-brand-width;
      height: $navbar-height;
      padding: $navbar-padding-y $navbar-padding-x;
      margin-right: 0;
      background-color: $navbar-brand-bg;
      background-image: $navbar-brand-logo;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: $navbar-brand-logo-size;
      bottom: 1px solid $navbar-border-color;
      color: white;
      font-size: 1.25rem;
    }

    .nav {
      position: relative;
      z-index: 100;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &-nav {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .nav {
      flex-direction: column;
    }

    .nav-heading {
      padding: 1.25rem 1.25rem 0.3rem;
      text-transform: uppercase;
      color: rgba($gray-400, 0.6);
      font-size: 0.75rem;
      letter-spacing: 1px;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.45);
      padding: 1rem 1.25rem;
      border-left: 0.25rem solid transparent;

      .nav-icon {
        display: block;
        min-width: 33px;
        color: rgba($text-muted, 0.6);
      }

      .nav-caret {
        color: rgba($text-muted, 0.6) !important;
      }

      span {
        display: flex;
        width: 100%;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: white;

        svg {
          color: theme-color('primary');
        }

        .nav-caret {
          svg {
            color: rgba($text-muted, 0.6) !important;
          }
        }
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.1);
        color: white;
        border-left: 0.25rem solid theme-color('primary');

        svg {
          color: theme-color('primary');
        }
      }

      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }

    .nav-item {
      .nav {
        background-color: rgba(0, 0, 0, 0.04);

        .nav-link {
          &:hover {
            background-color: rgba(0, 0, 0, 0.03) !important;
            svg {
              color: rgba(255, 255, 255, 0.3);
            }
          }

          &.active {
            background-color: rgba(0, 0, 0, 0.03) !important;
            border-left: 0.25rem solid transparent !important;
            svg {
              color: theme-color('primary');
            }
          }
        }
      }

      &.active,
      &.open {
        & > .nav-link {
          background-color: rgba(0, 0, 0, 0.1);
          color: white;

          svg {
            color: theme-color('primary');
          }

          .nav-caret {
            svg {
              color: rgba($text-muted, 0.6) !important;
            }
          }
        }
      }

      &.active {
        & > .nav-link {
          border-left: 0.25rem solid theme-color('primary');
        }
      }

      &.open {
        & > .nav-link {
          .nav-caret {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .tooltip {
    display: none;

    &-inner {
      white-space: nowrap;
    }
  }

  &-profile {
    width: 100%;
    background: darken($sidebar-bg, 1.5%);
    overflow: hidden;
    flex-shrink: 0;

    .profile {
      display: flex;
      align-items: center;
      padding: 1rem 1.25rem;
      width: 100%;
      color: white;

      &:hover,
      &.open {
        background: darken($sidebar-bg, 3%);
        text-decoration: none;
      }
    }

    fa-icon {
      color: rgba(255, 255, 255, 0.45);
    }

    .list-group {
      border: 0;

      &-item {
        background-color: transparent;
        border: 0;
        padding: 1rem $spacer;
      }

      &-item-action {
        color: theme-color('primary');
        text-align: right;

        &:hover {
          background: darken($sidebar-bg, 3%);
        }
      }
    }
  }

  @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
    @include toggled-sidebar;
  }

  @include media-breakpoint-down(md) {
    .navbar .navbar-brand {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

.sidebar-toggled {
  .sidebar {
    @include toggled-sidebar;
  }
}
