@mixin custom-form-validation-state-selector($state) {
  .form-validation.ng-submitted #{if(&, "&", "")}.ng-#{$state},
  .form-validation #{if(&, "&", "")}.ng-dirty.ng-#{$state} {
    @content;
  }
}

@mixin custom-form-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color: color-contrast($color),
  $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
  $focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($color, $input-btn-focus-color-opacity),
  $border-color: $color
) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    font-style: $form-feedback-font-style;
    color: $color;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: 0.1rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: $tooltip-color;
    background-color: $tooltip-bg-color;
    @include border-radius($form-feedback-tooltip-border-radius);
  }

  @include custom-form-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ .#{$state}-tooltip {
      display: block;
    }
  }

  .form-control {
    @include custom-form-validation-state-selector($state) {
      border-color: $border-color;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: escape-svg($icon);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }

      &:focus {
        border-color: $border-color;
        box-shadow: $focus-box-shadow;
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.form-control {
    @include custom-form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      }
    }
  }

  .form-select {
    @include custom-form-validation-state-selector($state) {
      border-color: $border-color;

      @if $enable-validation-icons {
        &:not([multiple]):not([size]),
        &:not([multiple])[size='1'] {
          --#{$prefix}form-select-bg-icon: #{escape-svg($icon)};
          padding-right: $form-select-feedback-icon-padding-end;
          background-position: $form-select-bg-position, $form-select-feedback-icon-position;
          background-size: $form-select-bg-size, $form-select-feedback-icon-size;
        }
      }

      &:focus {
        border-color: $border-color;
        box-shadow: $focus-box-shadow;
      }
    }
  }

  .form-control-color {
    @include custom-form-validation-state-selector($state) {
      @if $enable-validation-icons {
        width: add($form-color-width, $input-height-inner);
      }
    }
  }

  .form-check-input {
    @include custom-form-validation-state-selector($state) {
      border-color: $border-color;

      &:checked {
        background-color: $color;
      }

      &:focus {
        box-shadow: $focus-box-shadow;
      }

      ~ .form-check-label {
        color: $color;
      }
    }
  }
  .form-check-inline .form-check-input {
    ~ .#{$state}-feedback {
      margin-left: 0.5em;
    }
  }

  .input-group {
    > .form-control:not(:focus),
    > .form-select:not(:focus),
    > .form-floating:not(:focus-within) {
      @include custom-form-validation-state-selector($state) {
        @if $state == 'valid' {
          z-index: 3;
        } @else if $state == 'invalid' {
          z-index: 4;
        }
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include custom-form-validation-state($state, $data...);
}

// form validation

.form-validation {
  &.ng-submitted .ng-invalid:not(ng-form),
  .ng-invalid.ng-dirty:not(ng-form),
  .ng-submitted .ng-invalid {
    border-color: theme-color('danger') !important;
  }
}

.form-control-feedback {
  margin-top: 0.25rem;
  font-size: 0.875em;

  &:not(:first-child) {
    display: none;
  }
}

.error-messages,
ng-message {
  display: block;
  color: theme-color('danger');
}

.info-messages {
  display: none;
  color: theme-color('info');
}

input:required,
select:required {
  box-shadow: none;
}

// form control
.form-control {
  &::-webkit-input-placeholder {
    color: $input-placeholder-color;
  }
}

.has-info {
  .form-control {
    border-color: theme-color('info') !important;
  }

  .form-control-label {
    color: theme-color('info') !important;
  }

  .info-messages {
    display: block;
  }
}

.form-block {
  margin-bottom: $spacer;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

ng-select.border-info .ng-select-container {
  border-color: theme-color('info') !important;
}

ng-select.border-danger .ng-select-container {
  border-color: theme-color('danger') !important;
}

.input-large {
  flex-grow: 1;
}

.form-group {
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.5rem;
  }
}

.input-group.col {
  padding-left: 0;
  padding-right: 0;
}
