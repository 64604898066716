header-notifications {
  .scrollable .dropdown-item {
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }

    &.has-success {
      border-left: theme-color('success') 3px solid;
    }
    &.has-info {
      border-left: theme-color('info') 3px solid;
    }
    &.has-warning {
      border-left: theme-color('warning') 3px solid;
    }
    &.has-danger {
      border-left: theme-color('danger') 3px solid;
    }
  }
}
