.img-avatar {
  border-radius: 50%;
}

.avatar {
  $width: 48px;
  $status-width: 12px;
  @include avatar($width, $status-width);
}

.avatar.avatar-xs {
  $width: 26px;
  $status-width: 8px;
  @include avatar($width, $status-width);
}

.avatar.avatar-sm {
  $width: 36px;
  $status-width: 8px;
  @include avatar($width, $status-width);
}

.avatar.avatar-md {
  $width: 64px;
  $status-width: 11px;
  @include avatar($width, $status-width);
}

.avatar.avatar-lg {
  $width: 72px;
  $status-width: 12px;
  @include avatar($width, $status-width);
}

.avatar.avatar-xl {
  $width: 96px;
  $status-width: 12px;
  @include avatar($width, $status-width);
  height: $width;
}

.avatar.avatar-xxl {
  $width: 112px;
  $status-width: 12px;
  @include avatar($width, $status-width);
}

.avatar.avatar-border .img-avatar {
  border: 4px solid rgba(white, 0.1);
}

.avatar.avatar-border-xl .img-avatar {
  border: 5px solid $border-color !important;
}

.avatar.avatar-labeled {
  margin-right: 0.5rem;
}

.avatars-stack {
  .avatar.avatar-xs {
    margin-right: -10px;
  }

  .avatar {
    margin-right: -15px;
    transition:
      margin-left $layout-transition-speed,
      margin-right $layout-transition-speed;

    &:hover {
      margin-right: 0 !important;
    }
  }
}
