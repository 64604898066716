.app-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  padding: 1rem 0;

  &:after {
    content: ' ';
    display: block;
    clear: both;
  }

  .footer-left,
  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-left {
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      padding: 1.25rem 0 0;
      order: 2;
      text-align: center;
    }
  }

  .footer-right {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 0;
      order: 1;
      text-align: center;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
        flex-direction: column;
      }

      li {
        margin: 0 0 0 $spacer;

        @include media-breakpoint-down(md) {
          padding: 1rem 0;
          margin: 0;
        }

        a {
          display: block;
        }
      }
    }
  }
}
