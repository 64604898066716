video-player {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  width: 100%;

  & > iframe {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.modal.video-player > .modal-dialog > .modal-content {
  background: #000;
  border: none;
  display: block;
}
