.profile-card {
  overflow: hidden;
  height: 120px;
}

.profile-card-picture {
  height: 120px !important;
  width: 120px !important;
}

.btn-profile-card {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 1rem;
}
