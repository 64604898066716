.fee-table {
  thead {
    tr {
      th:nth-child(1) {
        width: 25%;
      }
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6) {
        width: 14%;
      }
    }
  }
}
