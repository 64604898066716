.ql-editor {
  min-height: 300px;
  font-size: 0.875rem !important;

  p {
    margin-bottom: 1rem !important;
  }

  strong {
    font-weight: bold;
  }

  ul {
    margin-bottom: 1rem !important;

    li {
      margin-bottom: 1rem !important;
    }
  }
}
