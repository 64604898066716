.media {
  display: flex;
  align-items: flex-start;

  .media-body {
    flex: 1;
  }
}

.media-info {
  margin-bottom: 0.5rem;
  @include media-breakpoint-down(md) {
    margin-right: 1rem;
  }
  @include media-breakpoint-down(sm) {
    margin-right: 0.5rem;
  }

  & > .media-body {
    padding-top: 0.75rem;
  }

  & > img {
    width: 110px;
    height: auto;
  }

  & > i {
    margin-left: $spacer;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }
}
