/* Loader spinner */
#loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $loader-bg;
  z-index: 100000;
  text-align: center;
  line-height: 100vh;
  font-size: 1.25rem;
  font-weight: 400;

  &:after {
    content: ' ';
    background-image: $loader-logo;
    background-repeat: no-repeat;
    background-position: center;
    animation: pulse 2s ease-in-out infinite;
    height: 192px;
    width: 192px;
    position: absolute;
    top: calc(50% - 76px);
    left: 50%;
    margin-top: -96px;
    margin-left: -96px;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
  }

  70% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(0.5);
  }
}

// Modern
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;

  &-inline {
    width: 15px;
    height: 15px;
    margin: 0;
    position: initial;
  }

  & .path {
    stroke: $gray-200;
    stroke-linecap: round;
    animation: modern-dash 1.5s ease-in-out infinite;
  }
  &-dark {
    & .path {
      stroke: $gray-800;
    }
  }
}
@keyframes modern-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes skeletonLoaderAnimation {
  0% {
    background-position: -490px 0;
  }
  100% {
    background-position: 490px 0;
  }
}

.skeleton-loader {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: skeletonLoaderAnimation;
  animation-timing-function: linear;

  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 980px 898px;
  height: 898px;
  position: relative;
}
