@import 'user-allocations/user-allocations.component';

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 5rem;
  align-items: stretch;
}

.cell {
  flex-grow: 1;
  max-width: 330px;
  padding: 1rem 0.5rem;
}

.product-box {
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #fff;
  color: $body-color;
  border-radius: 0.3rem;
  border: 1px solid $border-color;
  overflow: hidden;
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.05));

  & > a {
    height: 203px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.4s;
      border-radius: $border-radius-sm;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: linear-gradient(to bottom, transparent 35%, rgba(desaturate(darken($body-bg, 70%), 22%), 0.8));
      z-index: 1;
    }
  }

  &:hover {
    & > a > img {
      transform: scale3d(1.2, 1.2, 1);
    }
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacer;
  }

  &-title {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  &-text {
    word-wrap: break-word;
    flex: 2;
    margin-bottom: 1.25rem;
  }
}

.investment-badges {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc($spacer / 3 * 2);

  .type-badge {
    color: #fff;
    display: block;
    padding: 1px 10px;
    background-color: rgba(74, 74, 76, 0.7);
    border-radius: 3px;
    float: right;
    text-transform: capitalize;
  }
}

investment-confirmation {
  .list-group .list-group-item {
    padding: 1rem 1.25rem !important;
  }
}

.process-row {
  display: table-row;
}

.process {
  display: table;
  width: 100%;
  position: relative;
}

.process-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.process-row:before {
  top: 50px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}

.process-step {
  display: table-cell;
  text-align: center;
  position: relative;
  width: 25%;
}

.process-step p {
  margin-top: 10px;
}

.btn-circle {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 10px;
}
