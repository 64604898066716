// Fonts
/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-display: 'auto';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/rubik-v7-latin/rubik-v7-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Rubik Light'),
    local('Rubik-Light'),
    url('/fonts/rubik-v7-latin/rubik-v7-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/rubik-v7-latin/rubik-v7-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/rubik-v7-latin/rubik-v7-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-display: 'auto';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Rubik'),
    local('Rubik-Regular'),
    url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/rubik-v7-latin/rubik-v7-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-display: 'auto';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/rubik-v7-latin/rubik-v7-latin-500.eot'); /* IE9 Compat Modes */
  src:
    local('Rubik Medium'),
    local('Rubik-Medium'),
    url('/fonts/rubik-v7-latin/rubik-v7-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/rubik-v7-latin/rubik-v7-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/rubik-v7-latin/rubik-v7-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/rubik-v7-latin/rubik-v7-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
