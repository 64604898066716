.registration-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;

  h5 {
    text-transform: uppercase;
    font-size: 0.9375rem;
    font-weight: 500;
  }
}

.registration-logo {
  display: block;
  width: $login-logo-width;
  margin: 1.5rem auto;
}

.registration-box {
  display: block;
  width: 100%;
  max-width: 540px;
  margin: 0 auto 0.75rem;
  h3 {
    text-align: center;
  }

  &-lg {
    max-width: 800px;
  }
}

.form-check-large {
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius-lg;
  background: #fafafa;
  padding: 0.75rem;
  width: 100%;
  margin-bottom: 0;

  .form-check-input {
    margin-left: 0;
  }

  .form-check-label {
    margin-left: 12px;
    height: 40px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    float: left;
  }

  .form-check-icon {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border: 1px solid $border-color;
    border-radius: 8px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    fa-icon {
      color: theme-color('primary');
    }
  }
}

.wizard-icon-large {
  display: flex;
  justify-content: center;
  justify-content: center;
  font-size: 200px;
  color: theme-color('primary');
  line-height: 1;
}

.auth-step-title {
  font-size: 0.9375rem;
  font-weight: 500;
  color: $headings-color;
}
