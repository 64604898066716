@mixin avatar($width, $status-width) {
  position: relative;
  display: inline-block;
  width: $width;
  height: $width;

  .img-avatar {
    width: $width;
    height: $width;
    object-fit: cover;
  }

  .avatar-status {
    position: absolute;
    right: calc($width/16);
    bottom: calc($width/16);
    display: block;
    width: $status-width;
    height: $status-width;
    border: 2px solid darken($sidebar-bg, 1.5%);
    border-radius: 50%;
    background-color: #00d97e;
  }
}

@mixin borders($borders) {
  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == 'all' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border: $size $style $color;
    } @else if $direction == 'top' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-top: $size $style $color;
    } @else if $direction == 'right' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-right: $size $style $color;
    } @else if $direction == 'bottom' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-bottom: $size $style $color;
    } @else if $direction == 'left' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-left: $size $style $color;
    }
  }
}

@mixin bg-variant($parent, $color) {
  #{$parent} {
    @include border-radius(
      $card-border-radius-inner $card-border-radius-inner $card-border-radius-inner $card-border-radius-inner
    );
    color: #fff !important;
    background-color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}
@mixin card-accent-variant($color) {
  border-top-width: 2px;
  border-top-color: $color;
}
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1); // fallback
  background: linear-gradient($direction, $color-stops);
}
