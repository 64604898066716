.status-message {
  background-color: theme-color('primary');
  padding: 0.75rem 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  a {
    color: white;
  }
}
