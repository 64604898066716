figure {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem;

  fa-icon {
    font-size: 2.5rem;
    display: block;
    color: theme-color('primary');
  }
}

.chart-container {
  max-width: 100%;
  max-height: 150px;
  overflow-x: hidden;
}
