.alert-outlet-full-width {
  .alert {
    border-left: 0;
    border-right: 0;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    .btn-link {
      color: inherit;
    }
  }
}
