.table-clear {
  td {
    border: 0;
  }
}

th {
  font-weight: 500;
}

.table-col-sm {
  width: 5%;
}

//
//
// tables.scss
//
//

// Bootstrap overrides
//
// General overrides for default table styles

thead {
  tr {
    th {
      font-weight: $font-weight-normal;
      color: $headings-color;

      .btn-group {
        position: absolute;
        right: 0.7rem;
      }
    }
  }

  &.table-light th {
    color: #5a6269;
    background-color: #f8f9fb;
    border-color: $border-color;
    border-top: 1px solid $border-color;
  }
}

tbody {
  tr {
    th {
      font-weight: $font-weight-normal;
    }
  }
}

.table {
  .tooltip-inner {
    max-width: initial !important;
  }
  thead {
    th {
      border-bottom-width: 1px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  td,
  th {
    vertical-align: middle;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    .tooltip {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dropdown-toggle::after {
      content: none;
    }
  }
}

// Additional table styles
//
//

.table-borderless tr.bg-white {
  > td,
  > th {
    padding: 1rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-top: 1px solid $border-color;
      left: 0;
      top: 0;
      pointer-events: none;
    }
  }

  > th:after {
    border-left: 1px solid $border-color;
  }

  > td:last-child:after {
    border-right: 1px solid $border-color;
  }

  &:first-child {
    > th {
      border-top-left-radius: 0.5rem;

      &:after {
        border-top-left-radius: 0.5rem;
      }
    }

    > td:last-child {
      border-top-right-radius: 0.5rem;

      &:after {
        border-top-right-radius: 0.5rem;
      }
    }
  }

  &:last-child {
    > th {
      border-bottom-left-radius: 0.5rem;

      &:after {
        border-bottom-left-radius: 0.5rem;
      }
    }

    > td:last-child {
      border-bottom-right-radius: 0.5rem;

      &:after {
        border-bottom-right-radius: 0.5rem;
      }
    }

    > td,
    > th {
      &:after {
        border-bottom: 1px solid $border-color;
      }
    }
  }
}

.table-borderless tr {
  &.has-success {
    > td:first-child,
    > th:first-child {
      &:after {
        border-left: theme-color('success') 3px solid;
      }
    }
  }

  &.has-info {
    > td:first-child,
    > th:first-child {
      &:after {
        border-left: theme-color('info') 3px solid;
      }
    }
  }

  &.has-danger {
    > td:first-child,
    > th:first-child {
      &:after {
        border-left: theme-color('danger') 3px solid;
      }
    }
  }

  &.has-warning {
    > td:first-child,
    > th:first-child {
      &:after {
        border-left: theme-color('warning') 3px solid;
      }
    }
  }
}

.table-hover tr.bg-white:hover {
  background-color: $table-hover-bg !important;
}

tr.table-divider {
  height: calc($spacer/2);
}

table thead th {
  .sort-toggle {
    color: darken($border-color, 8%);
    font-size: 90%;
    margin-left: 0.25rem;

    &:hover {
      color: darken($border-color, 18%);
      cursor: pointer;
    }

    &.active {
      color: theme-color('primary');
    }
  }
}

tr.fill td {
  background: $gray-100 !important;
  border-color: $border-color !important;
}

tr:not(.fill) + tr.fill td {
  border-color: $table-border-color !important;
}

.table-card {
  margin-bottom: 0;

  td,
  th {
    &:first-child {
      padding-left: $card-spacer-x;
      border-left: 0 !important;
    }

    &:last-child {
      padding-right: $card-spacer-x;
      border-right: 0 !important;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;

      &:first-child {
        border-bottom-left-radius: var(--bs-border-radius);
      }

      &:last-child {
        border-bottom-right-radius: var(--bs-border-radius);
      }
    }
  }
}

.table-data {
  tbody th,
  tbody td {
    height: 47px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

td.action,
th.action {
  width: 1% !important;
}

.table-md td {
  padding: calc($table-cell-padding / 3 * 2);
}

.tx-marker {
  display: block;
  width: 2px;
  height: 20px;
  background-color: theme-color('primary');
  position: absolute;
  left: 41px;

  &.up {
    top: -1px;
  }

  &.down {
    bottom: 0px;
  }
}

.table-light {
  --bs-table-color: #{$text-muted};
  --bs-table-border-color: #{$border-color};
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-layout-fixed {
  table-layout: fixed;
}
