.nav-tabs-lg,
.nav-tabs-md {
  border-bottom: 1px solid $border-color !important;

  .nav-link.active {
    background-color: lighten($body-bg, 3%) !important;
  }
}

.nav-tabs-lg {
  .nav-link {
    padding: 0.75rem 1.5rem !important;
  }
}

.nav-tabs-md {
  .nav-link {
    padding: 0.75rem 1rem !important;
  }
}

.nav-tabs-alt {
  padding: 0.5rem;
  background: darken($body-bg, 3%);

  .nav-link {
    padding: 0.5rem;
    transition: color 0.35s ease-out;
    color: $text-muted;
    font-weight: 500;
    border-radius: 0.5rem;

    &.active {
      background-color: #fff;
      color: theme-color('primary');
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      color: theme-color('primary');
    }
  }

  &.nav-tabs-alt-inverse {
    .nav-link {
      border: 1px solid transparent;

      &:hover {
        background-color: darken(#ebeff5, 2.5%) !important;
        color: theme-color('dark');
      }
    }
    .nav-link.active {
      background-color: darken(#ebeff5, 5%) !important;
      border: 1px solid darken(#dae1ec, 5%);
    }
    border-bottom: 1px solid darken(#dae1ec, 2.5%);
  }
}

.modal-content .nav-tabs-alt {
  padding: calc($modal-inner-padding / 2) $modal-inner-padding !important;
}

.tab-nav {
  display: flex;
  justify-content: center;

  &-item {
    border: 2px solid transparent;
    background-color: transparent;
    border-width: 0 0 2px 0;
    color: #666;
    padding: 1rem 1.75rem;
    @include media-breakpoint-down(md) {
      padding: 1rem 1.2rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 1rem 0.55rem;
    }

    &.active,
    &:hover {
      color: $body-color;
      text-decoration: none;
      border-color: theme-color('primary');
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
}
