.quickbar {
  margin-bottom: $spacer;
  background: darken($body-bg, 4%);
  padding: calc($spacer / 2);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  align-items: center;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-item {
    min-width: 140px;
    flex: 1 1 0px;
    text-align: center;
    padding: calc($spacer / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  &-link {
    color: $body-color !important;
    background: white;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    width: 100%;
    padding: 1rem 0;
    font-weight: 500;
    transition:
      transform 0.15s ease-out,
      box-shadow 0.15s ease-out;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.25rem;

      fa-icon {
        font-size: 1.5rem;
        display: block;
        color: theme-color('primary');
      }
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.125);
      text-decoration: none;
    }

    &:active {
      transform: translateY(-1px);
      background: mix(white, $body-bg, 75%);
      box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    }

    &.disabled {
      transform: none;
      background: darken(mix(white, $body-bg, 75%), 2%);
      box-shadow: none;
      color: $gray-400 !important;

      fa-icon {
        color: $gray-400;
      }
    }

    &.featured-success {
      background: rgb(14, 185, 119);
      background: linear-gradient(146deg, rgba(14, 185, 119, 1) 0%, rgba(16, 146, 177, 1) 100%);
      color: white !important;

      figure {
        fa-icon {
          color: white;
        }
      }
    }

    &.featured-info {
      background: rgb(94, 137, 247);
      background: linear-gradient(146deg, rgba(94, 137, 247, 1) 0%, rgba(30, 187, 230, 1) 100%);
      color: white !important;

      figure {
        fa-icon {
          color: white;
        }
      }
    }

    &.featured-warning {
      background: rgb(247, 219, 94);
      background: linear-gradient(146deg, rgba(247, 219, 94, 1) 0%, rgba(230, 159, 30, 1) 100%);
      color: white !important;

      figure {
        fa-icon {
          color: white;
        }
      }
    }
  }
}

.statistic-box {
  margin: 0.5rem 0;
  .box-content {
    position: relative;
    margin: 0 auto;
    width: 190px;
    height: 190px;

    .box-chart-wrapper {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -56%;
      width: 112%;
      height: 190px;
    }
  }
}

.activity-feed {
  padding-left: 5px;

  .feed-item {
    position: relative;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    border-left: 2px solid #e4e8eb;

    &:last-child {
      border-color: transparent;
      padding-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -6px;
      width: 10px;
      height: 10px;
      border-radius: 6px;
      background: #fff;
    }

    &.success:after {
      border: 2px solid theme-color('success');
    }

    &.info:after {
      border: 2px solid theme-color('info');
    }

    &.danger:after {
      border: 2px solid theme-color('danger');
    }

    .date {
      position: relative;
      top: -5px;
      color: $gray-400;
      font-size: 80%;
    }

    .text {
      position: relative;
      top: -3px;
      font-size: 0.875rem;
    }
  }
}

.profile-completeness {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  user-avatar {
    position: absolute;
    display: block;
    width: 96px;
    height: 96px;
    top: 16px;
  }
}

.progress-circle {
  svg {
    transform: rotate(-90deg);
  }

  &-meter,
  &-value {
    fill: none;
  }

  &-meter {
    stroke: $gray-100;
  }

  &-value {
    stroke: $gray-200;
    transition: all 500ms ease-out;
  }
}

.profile-value {
  font-size: 0.9375rem;
}

.card-preview {
  display: block;
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  background: $gray-100;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 0.5rem 0;
  color: $body-color;

  &-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .table {
    td {
      &:first-child {
        padding-left: 0.8rem !important;
      }
      &:last-child {
        padding-right: 0.8rem !important;
      }
    }
    tbody tr:nth-of-type(odd) {
      background-color: #fdfdfd;
    }
  }

  &:hover {
    border-color: mix(theme-color('primary'), $border-color);
    cursor: pointer;
    text-decoration: none;
    color: $body-color;
  }
}

@media all and (max-width: 767px) {
  .account-balance-dropdown {
    .dropdown-menu.show {
      left: -200px !important;
      transform: none !important;
    }
  }
}

.badge-profile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20%;
  right: 12%;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid white;

  svg {
    top: 2px;
    position: relative;
  }
}

.dashboard-news {
  background-color: $gray-100;
  width: 300px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 8px;
  box-shadow:
    0 1px 6px 0 rgb(0 0 0 / 6%),
    0 2px 32px 0 rgb(0 0 0 / 16%);
  z-index: 9999;
  transition: all 1s ease-in-out;

  .header,
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    .wrapper {
      max-width: 90%;
    }
  }
}

.splash-item {
  position: relative;
  text-align: center;
  background-color: #fff;
  border: 1px solid $gray-200;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 32px 12px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-top: 1px solid theme-color('primary');
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    .box-line {
      background-color: theme-color('primary');
      opacity: 1;
    }

    fa-icon {
      color: theme-color('primary');
    }

    h4 {
      color: $gray-900;
    }
  }

  .box-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    opacity: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  fa-icon {
    font-size: 4.25rem;
    color: $gray-500;
    transition: all 300ms ease-in-out;
  }

  h4 {
    color: $gray-500;
    font-size: 1rem;
    line-height: 1.25rem;
    transition: all 300ms ease-in-out;
  }
}
