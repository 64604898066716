.withdraw-form {
  .cursor-default:hover {
    cursor: default;
  }

  .btn-disabled-gray {
    &:disabled {
      color: $gray-400;
    }
  }

  .currency-select-wrapper {
    width: 25%;
  }

  .custom-currency-select {
    width: 100%;
    height: 100%;
    border-color: #d3d8e4 !important;

    &.ng-select.ng-valid .ng-select-container,
    .form-validation.ng-submitted &.ng-valid .ng-select-container,
    .form-validation &.ng-valid.ng-dirty .ng-select-container {
      border-color: #d3d8e4 !important;
    }

    .ng-select-container {
      height: 100% !important;
      background-color: $gray-100 !important;
      color: $primary !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-color: #d3d8e4 !important;
    }
  }

  .custom-badge {
    background-color: $gray-200;
    color: $gray-600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  .estimation-text {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .estimates-line {
    border-left: 1px solid $border-color;
    height: calc(100% + 1rem);
    position: absolute;
    top: -1rem;
    left: 11px;
  }

  .custom-input {
    padding-top: 30px;
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;

    &.ng-valid {
      background-image: none !important;
    }
  }

  .custom-label {
    position: absolute;
    z-index: 6;
    left: 16px;
    top: 8px;
  }

  .text-row {
    min-height: 24px;
  }
}
