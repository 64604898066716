.payment-methods-invoice {
  min-width: 135px;
}

.list-group-checkbox {
  .list-group-item {
    fa-icon {
      font-size: 1.625rem !important;
      display: block;
      color: theme-color('primary') !important;
    }
  }
}
