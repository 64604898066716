.card {
  margin-bottom: $spacer;

  .card-header {
    font-size: 1.0625rem;
    color: $headings-color;
  }

  // Cards with color accent
  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      border-color: darken($value, 12.5%);

      .card-header {
        background-color: darken($value, 3%);
        border-color: darken($value, 12.5%);
      }
    }
  }
}

.card-footer,
.card-header {
  background: transparent;

  .dropdown-toggle:after {
    content: initial;
  }
}

.card-footer {
  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;
    }
  }
}

.card-menu {
  float: right;
  padding: 0;
  z-index: 1000;
}

.card-header .card-menu {
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

[class*='card-outline-'] {
  .card-body {
    background: #fff !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Cards with color accent
@each $color, $value in $theme-colors {
  .card-accent-#{$color} {
    @include card-accent-variant($value);
  }
}

// Card Actions
.card-header {
  > i {
    margin-right: calc($spacer/2);
  }

  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a,
    button {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      i {
        display: inline-body;
        transition: 0.4s;
      }

      .r180 {
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-prepend {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-full {
  margin-top: -$spacer;
  margin-right: calc(-1 * $grid-gutter-width / 2);
  margin-left: calc(-1 * $grid-gutter-width / 2);
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}
@include media-breakpoint-up(sm) {
  .card-columns {
    &.cols-2 {
      column-count: 2;
    }
  }
}

.card-placeholder {
  background: rgba(0, 0, 0, 0.025);
  border: 1px dashed $gray-400;
}

.card-headline {
  background: transparent;
  position: relative;
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  color: $body-color;
  display: block;

  small {
    font-size: 0.875rem;
    line-height: 2.1;
  }

  &::before {
    content: ' ';
    position: absolute;
    bottom: -1px;
    width: 60px;
    height: 1px;
    background-color: theme-color('primary');
  }

  &-plain {
    &::before {
      height: 0;
    }
  }
}

.accordion {
  .card {
    margin-bottom: 0;
  }
}

.card-deck {
  margin-bottom: $spacer;
  @include media-breakpoint-down(md) {
    flex-flow: row !important;
    flex-direction: column !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0;

    .card {
      margin-bottom: $spacer !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.card-header-tabs {
  .nav-item .active {
    border-bottom: 1px solid white;
  }
}

.card-alt {
  .card-header {
    border-bottom: 0;
    font-size: 1rem;
    padding: 0 0.2rem;
    position: absolute;
    top: -0.75rem;
    left: 0.75rem;
    background-color: white;
    color: theme-color('primary');
  }
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  border-radius: var(--bs-card-border-radius);
  z-index: 5;
}

.card-striped-top {
  border: 0;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
  overflow: hidden;
  position: relative;
}

.card-striped-top::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-bottom-width: calc(0.375rem - 2px);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: var(--bs-primary);
  border-bottom-color: transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  z-index: 10;
}

.card-striped-top::after {
  border-top-width: 4px;
  border-bottom-width: calc(0.375rem - 3px);
}
