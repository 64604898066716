// Bootstrap overrides

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fb !default; // gray-lightest
$gray-200: #e9ecef !default; // gray-lighter
$gray-300: #bcc4d4 !default; // gray-light
$gray-400: #a6aebe !default; // gray
$gray-500: #839ba8 !default;
$gray-600: #7f8fa4 !default;
$gray-700: #5a6269 !default;
$gray-800: #1b2a4e !default;
$gray-900: #060707 !default;
$black: #000 !default;

$blue: #387cec !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d94a3d !default;
$orange: #ffa523 !default;
$yellow: #ffc107 !default;
$green: #50b652 !default;
$teal: #42ba96 !default;
$cyan: #1f9bcf !default;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'black': $black,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
) !default;

$primary: $blue !default;
$secondary: $teal !default;
$success: $teal !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$medium: $gray-600 !default;
$dark: $gray-900 !default;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  medium: $medium,
  dark: $dark,
) !default;

$primary-border-subtle: mix(white, $primary, 70%);
$secondary-border-subtle: mix(white, $secondary, 70%);
$success-border-subtle: mix(white, $success, 70%);
$danger-border-subtle: mix(white, $danger, 70%);
$info-border-subtle: mix(white, $info, 70%);
$warning-border-subtle: mix(white, $warning, 70%);

// Grid containers
$container-max-widths: (
  sm: 1196px,
  md: 1197px,
  lg: 1198px,
  xl: 1199px,
  xxl: 1200px,
);
$grid-columns: 24;

// Options
//
$enable-dark-mode: false;
$enable-rfs: false;

$min-contrast-ratio: 1;

// Body
//
// Settings for the `<body>` element.

$body-bg: #f0f3f7 !default;
$body-color: #495057;

$border-color: #e2e7ee;

// Border radius

$font-family-sans-serif:
  'Rubik',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-weight-bold: 500;

$link-decoration: none;
$link-hover-decoration: underline;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$spacer: 1rem;

$headings-margin-bottom: $spacer;
$headings-font-weight: 400;
$headings-line-height: 1.1;
$headings-color: $gray-800;

$card-bg: $white;
$card-border-color: $border-color;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;

// forms
$input-bg: $white;
$input-disabled-bg: $gray-200;

$input-color: $body-color;
$input-border-color: #d3d8e4;
$input-placeholder-color: lighten($gray-400, 4.5%);

$input-group-addon-color: $gray-500;
$input-group-addon-bg: $gray-100;
$input-group-addon-border-color: $input-border-color;

$form-check-input-width: 1.14325em;
$form-check-min-height: 1rem;

// buttons
$btn-font-weight: 500;

// Modal
$modal-xl: 1200px;
$modal-md: 550px;
$modal-sm: 450px;
$modal-backdrop-bg: rgb(29, 37, 49);
$modal-backdrop-opacity: 0.7;
$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-inner-padding: $spacer;
$modal-footer-bg: $gray-100;
$modal-inner-padding: 1.5rem;

// Dropdown
$dropdown-bg: white;
$dropdown-border-color: $border-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $headings-color;
$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: rgba($primary, 0.1);
$dropdown-item-padding-y: 0.375rem;
$dropdown-header-color: inherit;

// Nav tabs
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: white;
$nav-tabs-link-active-border-color: $border-color;
$nav-tabs-link-active-color: $headings-color;

// List group
$list-group-bg: white;
$list-group-border-color: $border-color;
$list-group-border-radius: 0;
$list-group-item-padding-y: 1.125rem;
$list-group-item-padding-x: 1.5rem;

// Badge
$badge-pill-border-radius: 0.5rem;

// Table
$table-color: inherit;
$table-bg: transparent;
$table-cell-padding: 0.75rem;
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-striped-bg: lighten($gray-100, 0.5%);
$table-hover-bg: $gray-100;
$table-head-bg: $gray-100;

// Pagination
$pagination-bg: white;
$pagination-disabled-bg: white;

// Breadcrumb
$breadcrumb-padding-y: 1rem;

// Tooltip
$tooltip-max-width: none;

// Fixes
@function color($key: 'blue') {
  @return map-get($colors, $key);
}

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
