.fee-tag {
  .icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(theme-color('primary'), 0.15);

    figure {
      display: flex;
      justify-content: center;
      align-items: center;

      fa-icon {
        font-size: 1.5rem;
        display: block;
        color: theme-color('primary');
      }
    }
  }
}
