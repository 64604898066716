.transaction-flow {
  display: flex;
  padding: 1rem 0;

  .bubble {
    background: theme-color('secondary');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background-color: $gray-200;
    border-radius: 50%;
    color: theme-color('primary');
    line-height: 0.75;
  }

  .name-tag {
    line-height: 1.3;
  }

  .line {
    flex: 1 1 0;
    margin: 6px -1px 6px 0;
    border-right: 1px dashed $gray-400;
  }
}
