.input-group {
  .ng-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    .ng-select-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 100%;
      width: 100%;
    }

    .ng-dropdown-panel {
      min-width: 100%;
    }
  }
}
