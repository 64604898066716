.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dropzone-bg;
  color: $text-muted;
  border-radius: $border-radius;
  transition: border-color 0.3s;
  height: 160px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 0.5rem;
  border: 2px dashed $dropzone-border-color;
  padding: 0.75rem;
  text-align: center;

  &.dragover,
  &:hover {
    border-color: theme-color('primary');
  }

  &.invalid-dragover,
  &.ng-invalid {
    border-color: theme-color('danger');
  }
}

.dz-icon {
  display: block;
  margin-bottom: 10px;
  font-size: 30px;
  transition: color 0.3s;
  color: #e2e3e9;
}

.dropzone:hover .dz-icon {
  color: theme-color('primary');
}

.dropzone + .error-messages {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
