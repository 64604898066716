.transaction-slip {
  position: relative;
  min-height: 500px;
  margin: 0 auto 2.25rem;
  border: 1px solid $border-color;
  background: #fff;
  padding: 2.25rem;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.08));

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%),
      linear-gradient(-45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%);
    background-size: 11px 19px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    transform: rotateX(180deg);
    background: linear-gradient(45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%),
      linear-gradient(-45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%);
    background-size: 11px 19px;
  }

  .close {
    position: absolute;
    right: 2.25rem;
  }

  &-comment {
    background: $gray-200;
    padding: 18px 20px;
    line-height: 26px;
    border-radius: 7px;
    margin-bottom: $spacer;
    width: 90%;
    position: relative;

    &:after {
      bottom: 100%;
      left: 7%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $gray-200;
      border-width: 10px;
      margin-left: -10px;
    }
  }
}
