.callout {
  margin: $spacer 0;
  padding: $spacer;
  border-left: 3px solid #eee;
  color: $gray-700;

  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.callout-primary {
  background-color: #f4f8fa;
  border-color: theme-color('primary');

  h4,
  h5,
  h6 {
    color: $gray-400;
  }
}

.callout-secondary {
  background-color: #f3faf3;
  border-color: theme-color('secondary');

  h4,
  h5,
  h6 {
    color: darken(theme-color('secondary'), 5%);
  }
}

.callout-success {
  background-color: #f3faf3;
  border-color: theme-color('success');

  h4,
  h5,
  h6 {
    color: darken(theme-color('success'), 5%);
  }
}

.callout-info {
  background-color: #f4f8fa;
  border-color: theme-color('info');

  h4,
  h5,
  h6 {
    color: theme-color('info');
  }
}

.callout-warning {
  background-color: #fcf8f2;
  border-color: theme-color('warning');

  h4,
  h5,
  h6 {
    color: darken(theme-color('warning'), 5%);
  }
}

.callout-danger {
  background-color: #fdf7f7;
  border-color: theme-color('danger');

  h4,
  h5,
  h6 {
    color: darken(theme-color('danger'), 5%);
  }
}
