.file {
  &-status {
    font-size: 2rem;
    background: #fff;
  }

  &-name {
    word-break: break-all;
  }

  &-upload {
    .drop-box {
      margin: 1.25rem;
    }

    .list-group-item {
      border-color: $border-color;
      border-left: 0;
      border-right: 0;
      padding: 1.25rem;
      flex-flow: row nowrap;
      overflow: hidden;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: darken(color('white'), 2%);
        cursor: pointer;

        .file-status {
          background: darken(color('white'), 2%);
        }
      }

      &.uploading {
        &:hover {
          background: inherit;
          cursor: wait;

          .file-status {
            background: inherit;
          }
        }
      }
    }
  }
}

.drop-box {
  min-height: 300px;
  padding: 1rem;
  border: 2px dashed $border-color;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;

  &.dragover {
    background-color: darken(color('white'), 2%);
  }

  &-flexible {
    min-height: initial !important;
  }

  &-content {
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
  }

  &-icon {
    &:before {
      font-size: 4rem;
      line-height: 4rem;
    }
    color: theme-color('primary');
    margin-bottom: 0.3rem;
  }
}

.loader-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.loader-bar .bar {
  position: relative;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 2px;
  background: theme-color('primary');
  animation: exordo-loader 3s ease infinite;
  border-radius: 1px;
}

@keyframes exordo-loader {
  0% {
    left: 0;
  }

  50% {
    left: calc(100% - 80px);
  }

  100% {
    left: 0;
  }
}
