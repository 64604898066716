@each $color, $value in $theme-colors {
  .modal-#{$color} {
    .modal-header {
      background-color: $value;
      color: #fff !important;
      border-bottom: none;

      .modal-title {
        color: #fff;
      }

      a {
        color: $white;

        &:hover {
          color: darken($white, 10%);
        }
      }
    }

    .close-modal {
      color: rgba($white, 0.9);

      &:focus,
      &:hover {
        color: $white;
      }
    }
  }
}

.modal-header {
  padding: 1rem $modal-inner-padding;
  border-bottom: 1px solid $border-color;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: var(--bs-modal-border-radius);
  border-top-left-radius: var(--bs-modal-border-radius);
}

.modal-title {
  font-weight: 400;
}

.close-modal {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 0.5;
}

.modal-subheader {
  display: flex;
  align-content: center;
  align-items: center;
  padding: calc($modal-inner-padding / 2) $modal-inner-padding;
  background-color: $gray-800;
  color: $white;
}

.modal-body {
  &.scrollable {
    max-height: 450px;
    overflow: auto;
  }

  label {
    font-size: $font-size-base;
  }

  .dropdown-toggle::after {
    content: none;
  }
}

.modal-footer {
  background: transparent;
  border-top: 1px solid $border-color;
  padding: 1rem $modal-inner-padding;
}

.modal-md {
  max-width: 680px;
}

.security-check-modal {
  z-index: 99999 !important;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  transform: translate3d(0%, 0, 0);

  .modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 1px solid #dadfe8;

    .btn-round {
      color: $gray-600 !important;
    }
  }

  .modal-content {
    background: white;
    & :first-child {
      & > form {
        position: relative;
        min-height: 100vh;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid #dadfe8;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
  }
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left.fade .modal-dialog {
  left: -320px;
  transition:
    opacity 0.3s linear,
    left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0;
}

.modal.right.fade .modal-dialog {
  right: -320px;
  transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.modal-body .form-group.row {
  margin-right: 0;
}
